import React from "react";
import "./ImagesCollage.scss";
import Image from "@common/Image/Image";
import classNames from "classnames";
import {
    SHIFTED_IMAGES_COLLAGE,
    UNSHIFTED_IMAGES_COLLAGE,
    REVERSE_IMAGES_COLLAGE,
    DEFAULT_IMAGES_COLLAGE,
} from "@constants";

const ImagesCollage = ({
    imagesDescription,
    collageType,
    shiftType,
    collageImages,
    isLazyLoad = true,
}) => {
    const { mainImageInfo, leftImageInfo, rightImageInfo } = collageImages;
    const imagesCollageClassName = classNames("office-images-collage", {
        "office-images-collage--shifted": shiftType === SHIFTED_IMAGES_COLLAGE,
    });
    const imagesCollageContainerClassName = classNames(
        "office-images-collage__container",
        {
            "office-images-collage__container--reverse":
                collageType === REVERSE_IMAGES_COLLAGE,
        }
    );

    return (
        <div className={imagesCollageClassName}>
            {imagesDescription && (
                <p className="office-images-collage__description">
                    {imagesDescription}
                </p>
            )}
            <div className={imagesCollageContainerClassName}>
                <Image
                    src={mainImageInfo.src}
                    srcSet={mainImageInfo.srcSet}
                    className="office-images-collage__main-image"
                    alt={mainImageInfo.altText}
                    isLazy={!!isLazyLoad}
                />
                {leftImageInfo && rightImageInfo && (
                    <div className="office-images-collage__images-container">
                        <div className="office-images-collage__image-container">
                            <Image
                                src={leftImageInfo.src}
                                srcSet={leftImageInfo.srcSet}
                                className="office-images-collage__image"
                                alt={leftImageInfo.altText}
                                isLazy={!!isLazyLoad}
                            />
                        </div>
                        <div className="office-images-collage__image-container">
                            <Image
                                src={rightImageInfo.src}
                                srcSet={rightImageInfo.srcSet}
                                className="office-images-collage__image"
                                alt={rightImageInfo.altText}
                                isLazy={!!isLazyLoad}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

ImagesCollage.defaultProps = {
    mainImageInfo: {
        src: null,
        srcSet: "",
    },
    collageType: DEFAULT_IMAGES_COLLAGE,
    shiftType: UNSHIFTED_IMAGES_COLLAGE,
};

export default ImagesCollage;
