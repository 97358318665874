import React from "react";
import "./ImagesCarousel.scss";
import Image from "@common/Image/Image";

const ImagesCarousel = ({ imagesDescription, imagesCarousel }) => (
    <div className="office-image-carousel">
        {imagesDescription && (
            <p className="office-image-carousel__description">
                {imagesDescription}
            </p>
        )}
        <div className="office-image-carousel__container">
            {imagesCarousel &&
                imagesCarousel.map((object, index) => (
                    <div
                        key={index}
                        className="office-image-carousel__images-container"
                    >
                        <Image
                            alt={object.mainImageInfo.alt}
                            className="office-image-carousel__image"
                            srcSet={object.mainImageInfo.srcSet}
                            src={object.mainImageInfo.src}
                            isLazy={false}
                        />
                        {object.leftImageInfo && (
                            <Image
                                alt={object.leftImageInfo.alt}
                                className="office-image-carousel__image"
                                srcSet={object.leftImageInfo.srcSet}
                                src={object.leftImageInfo.src}
                                isLazy={false}
                            />
                        )}
                        {object.rightImageInfo && (
                            <Image
                                alt={object.rightImageInfo.alt}
                                className="office-image-carousel__image"
                                srcSet={object.rightImageInfo.srcSet}
                                src={object.rightImageInfo.src}
                                isLazy={false}
                            />
                        )}
                    </div>
                ))}
        </div>
    </div>
);

export default ImagesCarousel;
