import React from "react";
import "./OfficeHeading.scss";

const OfficeHeading = () => (
    <div className="office-heading">
        <h1 className="office-heading__heading">Our office</h1>
        <p className="office-heading__heading-description">
            When choosing the place for our new office, we understood that we
            prefer a beautiful
            <br />
            historic building to a modern business centre. We wanted the office
            to embody
            <br />
            Impressit’s philosophy, our culture, ideas, and beliefs.
        </p>
    </div>
);

export default OfficeHeading;
